<template>
  <b-container
    ref="wrapper"
    v-resize="onResize"
    fluid
    class="item-list-wrapper d-flex flex-column flex-fill px-1 px-md-2"
  >
    <data-filter-vertical
      v-if="patchedFilterFields && filterVertical && !isMobile"
      ref="filter"
      v-model="filterState"
      :fields="patchedFilterFields"
      :filter="tFilter"
      :filter-data="patchedFilterData"
      :class="{'small': isMobile}"
      :height="height"
      @submit="onFilterSubmit"
    />
    <errors :items="listErrors" />
    <data-fast-add
      v-if="canFastAdd"
      ref="fastAdd"
      :repo="repo"
      :fields="editFields"
    />
    <div
      ref="topBar"
      class="top-bar-wrapper d-flex align-items-start justify-content-between pb-2"
      :class="{
        'mt-2':isDesktop
      }"
    >
      <div class="flex-grow-1 mr-1 d-inline-flex flex-wrap justify-content-between align-items-start">
        <slot
          name="top-bar"
          :data="{ metaData: metaData(), canSearch, searchQuery, searchList, searchBusy }"
        >
          <data-filter-inline
            :fields="patchedFilterFields"
            :filter="tFilter"
            :filter-data="patchedFilterData"
            @submit="onFilterSubmit"
          />
          <pagination
            v-if="isMobile"
            ref="pagerMobile"
            :ukey="dtKey"
            :limit="pageSize"
            :items="items"
            class="mb-2"
            :class="{'small': isMobile, 'mr-1': !isMobile}"
            @page-changed="onPageChanged"
            @size-changed="onPageSizeChanged"
          />
          <data-search
            v-if="!isMobile && canSearch"
            v-model="searchState"
            :query="searchQuery"
            :list="searchList"
            :is-busy="searchBusy"
            :placeholder="searchPlaceholder"
            :info="searchInfo"
            class="flex-grow-1 mr-1"
            @change-query="onSearchQuery"
            @search="onSearch"
            @flush-results="onFlushSearchResults"
          >
            <template #autocomplete-item="d">
              <slot
                name="data-search-autocomplete-item"
                :data="{ items: d.items, isCustom: d.isCustom, onSearch: d.onSearch }"
              />
            </template>
          </data-search>
        </slot>
        <div
          class="action-wrapper"
          :style="isMobile ? 'display: contents;' : ''"
        >
          <div
            v-if="!isMobile && canBatch"
            class="batch-wrapper d-inline-flex align-items-center justify-content-center text-nowrap small mb-2 mr-1"
          >
            <b-button-group
              size="sm"
              class="ml-1"
            >
              <div
                v-if="tableLayout === 'card'"
                class="btn btn-outline-info btn-sm"
              >
                <b-checkbox
                  id="batch-all"
                  v-model="allSelected"
                  :indeterminate="indeterminate"
                  @change="onSelectAll"
                >
                  {{ $t('eDoc_list_batch_select_all') }}
                </b-checkbox>
              </div>
              <b-dropdown
                v-if="selected.length > 0"
                size="sm"
                variant="outline-info"
                :text="$t('eDoc_list_batch_action_label_sm', { count: selectedCount })"
              >
                <slot
                  name="batch-menu-actions"
                  :selected="selected"
                  :layout="'dropdown'"
                />
              </b-dropdown>
            </b-button-group>
          </div>

          <data-sort
            v-if="tableLayout === 'card'"
            ref="sort"
            v-model="sortState"
            :fields="listFields"
            :order="tOrder"
            :def="defaultOrder"
            class="mb-2"
            :class="{'small': isMobile, 'mr-1': !isMobile}"
            @on-sort="onSort"
            @on-shown="onSortShown"
            @on-hidden="onSortHidden"
          />
          <data-layout-switcher
            v-if="!isMobile"
            class="mr-1"
          />
          <component
            :is="actionsInline ? 'b-button-group' : 'b-dropdown'"
            no-caret
            right
            dropleft
            size="sm"
            variant="outline-secondary"
          >
            <template
              v-if="!actionsInline"
              #button-content
            >
              <font-awesome-icon :icon="['fas', 'gear']" />
            </template>

            <component
              :is="actionsInline ? 'b-button' : 'b-dropdown-item-button'"
              v-if="canCreate"
              variant="success"
              @click="onCreate"
            >
              <font-awesome-icon
                :icon="['fas', 'plus']"
              />
              {{ !(isMobile && actionsInline) ? $t('eDoc_form_a_create') : '' }}
            </component>

            <component
              :is="actionsInline ? 'b-button' : 'b-dropdown-item-button'"
              v-if="isMobile && canSearch"
              :variant="actSearchBtnClass"
              @click="toggleSearch"
            >
              <font-awesome-icon
                :icon="['fas', 'magnifying-glass']"
              />
              {{ !(isMobile && actionsInline) ? $t('eDoc_form_a_search') : '' }}
            </component>

            <component
              :is="actionsInline ? 'b-button' : 'b-dropdown-item-button'"
              v-if="filterFields.length > 0"
              :variant="actFilterBtnClass"
              @click="toggleFilter"
            >
              <font-awesome-icon
                :icon="['fas', 'filter']"
              />
              {{ !(isMobile && actionsInline) ? $t('eDoc_form_a_filter') : '' }}
            </component>

            <component
              :is="actionsInline ? 'b-button' : 'b-dropdown-item-button'"
              v-if="tableLayout === 'card' && !!cardCollapse"
              :variant="actFoldingBtnClass"
              @click="collapseCards"
            >
              <font-awesome-icon icon="chevron-up" />
              {{ !(isMobile && actionsInline) ? $t('eDoc_form_a_collapse') : '' }}
            </component>

            <component
              :is="actionsInline ? 'b-button' : 'b-dropdown-item-button'"
              v-if="tableLayout === 'card' && !!cardCollapse"
              :variant="actFoldingBtnClass"
              @click="expandCards"
            >
              <font-awesome-icon icon="chevron-down" />
              {{ !(isMobile && actionsInline) ? $t('eDoc_form_a_expand') : '' }}
            </component>

            <b-dropdown-form
              v-if="isMobile"
            >
              <b-checkbox
                id="batch-all"
                v-model="allSelected"
                :indeterminate="indeterminate"
                @change="onSelectAll"
              >
                {{ $t('eDoc_list_batch_select_all') }}
              </b-checkbox>
            </b-dropdown-form>

            <b-dropdown-group
              v-if="isMobile && selected.length > 0"
              :header="$t('eDoc_list_batch_action_label', { count: selectedCount })"
              header-variant="outline-secondary"
            >
              <slot
                name="batch-menu-actions"
                :selected="selected"
                :layout="actionsInline ? 'group' : 'dropdown'"
              />
            </b-dropdown-group>

            <data-layout-switcher
              v-if="isMobile"
              :layout="actionsInline ? 'group' : 'dropdown'"
            />

            <pagination-page-size
              v-if="isMobile && pageSize"
              v-model="pageSize"
              :layout="actionsInline ? 'input' : 'dropdown'"
            />
          </component>
          <!--
          <b-dropdown
            v-if="!actionsInline"
            size="sm"
            :variant="actionsBtnClass"
            no-caret
            dropright
          >
            <template #button-content>
              <font-awesome-icon :icon="['fas', 'gear']" />
            </template>

            <b-dropdown-item-button
              v-if="canCreate"
              variant="success"
              @click="onCreate"
            >
              <font-awesome-icon
                :icon="['fas', 'plus']"
              />
              {{ $t('eDoc_form_a_create') }}
            </b-dropdown-item-button>

            <b-dropdown-item-button
              v-if="isMobile && canSearch"
              :variant="searchState ? 'primary' : actSearchBtnClass"
              @click="toggleSearch"
            >
              <font-awesome-icon
                :icon="['fas', 'magnifying-glass']"
              />
              {{ $t('eDoc_form_a_search') }}
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="filterFields.length > 0"
              :variant="filterState ? 'primary' : actFilterBtnClass"
              @click="toggleFilter"
            >
              <font-awesome-icon
                :icon="['fas', 'filter']"
              />
              {{ $t('eDoc_form_a_filter') }}
            </b-dropdown-item-button>

            <b-dropdown-group
              v-if="tableLayout === 'card' && !!cardCollapse"
              :header="$t('eDt_lt_folding')"
            >
              <b-dropdown-item-button
                variant="secondary"
                @click="collapseCards"
              >
                <font-awesome-icon icon="chevron-up" />
                {{ $t('eDoc_form_a_collapse') }}
              </b-dropdown-item-button>
              <b-dropdown-item-button
                variant="secondary"
                @click="expandCards"
              >
                <font-awesome-icon icon="chevron-down" />
                {{ $t('eDoc_form_a_expand') }}
              </b-dropdown-item-button>
            </b-dropdown-group>

            <data-layout-switcher
              v-if="isMobile"
              layout="dropdown"
            />

            <pagination-page-size
              v-if="isMobile && pageSize"
              v-model="pageSize"
              layout="dropdown"
            />
          </b-dropdown>
          -->
        </div>
      </div>
    </div>
    <data-search
      v-if="isMobile && canSearch"
      v-model="searchState"
      :query="searchQuery"
      :list="searchList"
      :is-busy="searchBusy"
      :placeholder="searchPlaceholder"
      :info="searchInfo"
      class="pb-2"
      close-btn
      @change-query="onSearchQuery"
      @search="onSearch"
      @flush-results="onFlushSearchResults"
      @on-close="hideSearch"
    >
      <template #autocomplete-item="d">
        <slot
          name="data-search-autocomplete-item"
          :data="{ items: d.items, isCustom: d.isCustom, onSearch: d.onSearch }"
        />
      </template>
    </data-search>
    <data-filter
      v-if="patchedFilterFields"
      ref="filter"
      v-model="filterState"
      :fields="patchedFilterFields"
      :filter="tFilter"
      :filter-data="patchedFilterData"
      :class="{'small': isMobile}"
      :badges-only="filterVertical && !isMobile"
      @submit="onFilterSubmit"
      @on-shown="onFilterShown"
      @on-hidden="onFilterHidden"
    />
    <data-table
      v-if="tableLayout === 'table' && routeQueryFetched"
      ref="dt"
      class="flex-grow-1 text-nowrap"
      :repo="repo"
      :fields="itemListFields"
      :filter="tFilter"
      :order="tOrder"
      :search="searchQuery"
      :tbody-tr-class="tBodyTrClass"
      :height="height"
      :delayed-call="true"
      :limit="pageSize"
      :page="currentPage"
      @on-sort="onSort"
      @on-call-added="onTableCallAdded"
    >
      <template
        v-for="field in listFields"
        #[`cell(${field.key})`]="data"
      >
        <slot
          :name="`cell(${field.key})`"
          :data="{ item: data.data.item, index: data.data.index, field: data.data.field }"
        />
      </template>
      <template #head(batch)="">
        <b-checkbox
          id="batch-all"
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="onSelectAll"
        />
      </template>
      <template #cell(batch)="{ data: { item } }">
        <b-checkbox
          v-model="selected"
          :value="item.id"
          class="batch"
        />
      </template>
      <template #cell(actions)="{ data }">
        <slot
          v-if="data"
          :name="`cell(actions)`"
          :data="{ item: data.item, index: data.index, field: data.field }"
        />
        <div
          v-if="data && data.item"
          class="actions-list d-flex justify-content-end"
        >
          <b-btn
            v-if="canEdit"
            variant="primary"
            size="sm"
            :title="$t('eDoc_list_a_edit')"
            @click="onEdit(data.item.id)"
          >
            <font-awesome-icon
              :icon="['fas', 'pen']"
            />
          </b-btn>
          <b-btn
            v-if="canDelete && !data.item.deleted_at"
            variant="danger"
            size="sm"
            class="ml-1"
            :title="$t('eDoc_list_a_delete')"
            :disabled="isLocked(repo, data.item.id)"
            @click="onDelete(data.item.id)"
          >
            <font-awesome-icon
              :icon="['fas', 'trash']"
            />
          </b-btn>
          <b-btn
            v-if="canRestore && data.item.deleted_at"
            variant="warning"
            size="sm"
            class="ml-1"
            :title="$t('eDoc_list_a_restore')"
            :disabled="isLocked(repo, data.item.id)"
            @click="onRestore(data.item.id)"
          >
            <font-awesome-icon
              :icon="['fas', 'trash-arrow-up']"
            />
          </b-btn>
        </div>
      </template>
      <template #pagination>
        <pagination
          v-if="!isMobile"
          ref="pager"
          :ukey="dtKey"
          :limit="pageSize"
          :items="items"
          class="py-2 border-top w-100"
          @page-changed="onPageChanged"
          @size-changed="onPageSizeChanged"
        />
      </template>
    </data-table>
    <data-cards
      v-if="tableLayout === 'card' && routeQueryFetched"
      ref="dt"
      class="flex-grow-1 text-nowrap"
      :repo="repo"
      :fields="listFields"
      :filter="tFilter"
      :order="tOrder"
      :search="searchQuery"
      :set-item-variant="cardBodyClass"
      :height="height"
      :delayed-call="true"
      :limit="pageSize"
      :page="currentPage"
      :collapse="cardCollapse"
      @on-sort="onSort"
      @on-call-added="onTableCallAdded"
    >
      <template
        v-if="hasSlot('card(header)')"
        #card(header)="{ data }"
      >
        <slot
          name="card(header)"
          :data="{ item: data.item, index: data.index }"
        />
      </template>
      <template #head(batch)="{ label }">
        <b-checkbox
          id="batch-all"
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="onSelectAll"
        >
          {{ label }}
        </b-checkbox>
      </template>
      <template
        v-if="canBatch"
        #cell(batch)="{ data: { item } }"
      >
        <b-checkbox
          v-model="selected"
          :value="item.id"
          class="batch"
          size="lg"
        />
      </template>
      <template
        v-if="hasSlot('card(actions)') || canEdit || (canDelete) || (canRestore)"
        #card(actions)="{ data }"
      >
        <slot
          name="card(actions)"
          :data="{ item: data.item, index: data.index }"
        >
          <b-dropdown-item-button
            v-if="canEdit"
            variant="primary"
            size="sm"
            :title="$t('eDoc_list_a_edit')"
            @click="onEdit(data.item.id)"
          >
            <font-awesome-icon
              :icon="['fas', 'pen']"
              class="mr-2"
              size="sm"
            />
            {{ $t('eDoc_form_labelEdit') }}
          </b-dropdown-item-button>
          <b-dropdown-item-button
            v-if="canDelete && !data.item.deleted_at"
            variant="danger"
            size="sm"
            class="ml-1"
            :title="$t('eDoc_form_labelDelete')"
            :disabled="isLocked(repo, data.item.id)"
            @click="onDelete(data.item.id)"
          >
            <font-awesome-icon
              :icon="['fas', 'trash']"
              class="mr-2"
              size="sm"
            />
            {{ $t('eDoc_form_labelDelete') }}
          </b-dropdown-item-button>
          <b-dropdown-item-button
            v-if="canRestore && data.item.deleted_at"
            variant="warning"
            size="sm"
            class="ml-1"
            :title="$t('eDoc_form_labelRestore')"
            :disabled="isLocked(repo, data.item.id)"
            @click="onRestore(data.item.id)"
          >
            <font-awesome-icon
              :icon="['fas', 'trash-arrow-up']"
              class="mr-2"
              size="sm"
            />
            {{ $t('eDoc_form_labelRestore') }}
          </b-dropdown-item-button>
        </slot>
      </template>
      <template
        v-if="hasSlot('card(body)')"
        #card(body)="{ data }"
      >
        <slot
          name="card(body)"
          :data="{ item: data.item, index: data.index }"
        />
      </template>
      <template
        v-for="field in listFields"
        #[`cell(${field.key})`]="{ data }"
      >
        <slot
          :name="`cell(${field.key})`"
          :data="{ item: data.item, index: data.index, field: data.field }"
        />
      </template>
      <template #cell(actions)="{ data }">
        <slot
          v-if="data"
          :name="`cell(actions)`"
          :data="{ item: data.item, index: data.index, field: data.field }"
        />
        <div
          v-if="data && data.item"
          class="actions-list d-flex justify-content-around"
        >
          <b-btn
            v-if="canEdit"
            variant="primary"
            size="sm"
            :title="$t('eDoc_list_a_edit')"
            @click="onEdit(data.item.id)"
          >
            <font-awesome-icon
              :icon="['fas', 'pen']"
            />
            {{ $t('eDoc_form_labelEdit') }}
          </b-btn>
          <b-btn
            v-if="canDelete && !data.item.deleted_at"
            variant="danger"
            size="sm"
            class="ml-1"
            :title="$t('eDoc_form_labelDelete')"
            :disabled="isLocked(repo, data.item.id)"
            @click="onDelete(data.item.id)"
          >
            <font-awesome-icon
              :icon="['fas', 'trash']"
            />
            {{ $t('eDoc_form_labelDelete') }}
          </b-btn>
          <b-btn
            v-if="canRestore && data.item.deleted_at"
            variant="warning"
            size="sm"
            class="ml-1"
            :title="$t('eDoc_form_labelRestore')"
            :disabled="isLocked(repo, data.item.id)"
            @click="onRestore(data.item.id)"
          >
            <font-awesome-icon
              :icon="['fas', 'trash-arrow-up']"
            />
            {{ $t('eDoc_form_labelRestore') }}
          </b-btn>
        </div>
      </template>
      <template #pagination>
        <pagination
          v-if="!isMobile"
          ref="pager"
          :ukey="dtKey"
          :limit="pageSize"
          :items="items"
          class="py-2 border-top w-100"
          @page-changed="onPageChanged"
          @size-changed="onPageSizeChanged"
        />
      </template>
      <template v-if="hasSlot('batch')" #batch>
        <slot
          v-if="canBatch && (items && items.data.length > 0)"
          name="batch"
          :selected="selected"
        />
      </template>
    </data-cards>
    <slot
      :canCreate="canCreate"
      :canEdit="canEdit"
      :editState="editState"
      :repo="repo"
      :editFields="editFields"
      :editId="editId"
      :onCancel="onCancel"
      :onSubmit="onSubmit"
    >
      <item-form
        v-if="canCreate || canEdit"
        v-model="editState"
        :repo="repo"
        :fields="editFields"
        :item-id="editId"
        @on-cancel="onCancel"
        @on-submit="onSubmit"
        @on-create="onSubmitNew"
      />
    </slot>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import page from '~/mixins/page'

export default {
  ...page,
  name: 'ItemList',
  props: {
    ...page.props,
    repo: {
      type: String,
      default: ''
    },
    listFields: {
      type: Array,
      required: true,
      default: () => ([])
    },
    filterFields: {
      type: Array,
      required: false,
      default: () => ([])
    },
    editFields: {
      type: Array,
      required: false,
      default: () => ([])
    },
    canCreate: {
      type: Boolean,
      default: true
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    canRestore: {
      type: Boolean,
      default: false
    },
    canSearch: {
      type: Boolean,
      default: true
    },
    canFastAdd: {
      type: Boolean,
      default: false
    },
    setItemVariant: {
      type: [String, Function],
      default: ''
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...'
    },
    searchInfo: {
      type: Array,
      default: () => ([])
    },
    cardCollapse: {
      type: Boolean,
      default: false
    },
    actionsInline: {
      type: Boolean,
      default: false
    },
    filterVertical: {
      type: Boolean,
      default: false
    },
    canBatch: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ...page.data(),
      editState: false,
      editId: null,
      filterState: false,
      sortState: false,
      searchState: false,
      searchBusy: false,
      height: 500,
      dtKey: null,
      isMounted: false,
      defaultOrder: this.order,
      pageSize: parseInt(this.$route?.query?.limit, 10) || parseInt(this.limit, 10),
      // batch actions
      allSelected: false,
      indeterminate: false,
      selected: []
    }
  },
  fetch () {
    const query = {}
    if (this.filterFields.length) {
      if (this.tFilter && this.tFilter.length) {
        for (const filter of this.tFilter) {
          query[`filter[${filter.column}]`] = filter.value
        }
      }
      this.addCall({
        repo: this.repo,
        method: 'filterData',
        query
      })
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      isLocked: 'shared/isLocked',
      errors: 'repos/errors',
      deviceInfo: 'device',
      tableLayout: 'tableLayout'
    }),
    items () {
      return this.getByName(`${this.repo}/index`)
    },
    filterData () {
      return this.getByName(`${this.repo}/filterData`)
    },
    searchList () {
      const dataObj = this.getByName(`${this.repo}/search`)
      return dataObj && dataObj.data
    },
    filterMetaData () {
      return this.items && this.items.meta && this.items.meta.filter
    },
    patchedFilterFields () {
      const filterFields = this.filterFields
      if (this.canRestore) {
        filterFields.push({
          label: this.$t('eDoc_list_s_trashed'),
          key: 'trashed',
          type: 'select',
          class: 'col-12 col-md-6 col-lg-2'
        })
      }
      return filterFields
    },
    patchedFilterData () {
      const filterData = this.filterData ? JSON.parse(JSON.stringify(this.filterData)) : {}
      if (this.canRestore) {
        filterData.trashed = [
          {
            id: 'with',
            text: this.$t('eDoc_list_s_withTrashedOption')
          },
          {
            id: 'only',
            text: this.$t('eDoc_list_s_onlyTrashedOption')
          }
        ]
      }
      return !Array.isArray(filterData) ? filterData : {}
    },
    listErrors () {
      return this.errors.filter((error) => {
        return [
          'index',
          'delete',
          'filterData',
          'search'
        ].includes(error.method)
      })
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile'
    },
    isDesktop () {
      return this.deviceInfo?.type === 'desktop'
    },
    actionsBtnClass () {
      return this.searchQuery !== '' || this.tFilter.length > 0
        ? 'outline-warning'
        : 'outline-secondary'
    },
    actSearchBtnClass () {
      let cls = this.searchQuery !== ''
        ? 'warning'
        : 'secondary'
      if (this.searchState) {
        cls = 'primary'
      }
      if (this.actionsInline) {
        cls = `outline-${cls}`
      }
      return cls
    },
    actFilterBtnClass () {
      let cls = this.tFilter.filter(i => !i.permanent).length > 0
        ? 'warning'
        : 'secondary'
      if (this.filterState) {
        cls = 'primary'
      }
      if (this.actionsInline) {
        cls = `outline-${cls}`
      }
      return cls
    },
    actFoldingBtnClass () {
      return this.actionsInline ? 'outline-secondary' : 'secondary'
    },
    itemListFields () {
      if (this.canBatch) {
        return [
          {
            key: 'batch',
            label: '',
            class: '',
            thClass: 'text-center',
            sortable: false
          },
          ...this.listFields
        ]
      }
      return this.listFields
    },
    selectedCount () {
      return this.selected.length
    }
  },
  watch: {
    ...page.watch,
    filterMetaData: {
      handler (n) {
        if (n && this.filterFields.length) {
          Object.keys(n).forEach((k) => {
            if (
              this.filterFields.map(i => i.key).includes(k)
            ) {
              if (!this.tFilter.find(i => i.column === k)) {
                this.tFilter.push({
                  column: k,
                  value: n[k]
                })
              }
            }
          })
        }
      },
      deep: true,
      immediate: true
    },
    currentPage (n) {
      this.updatePageQuery()
      if (n && this.isMounted) {
        this.$fetch()
      }
    },
    pageSize (n) {
      this.updatePageQuery()
      if (n && this.isMounted) {
        this.currentPage = 1
        this.$nextTick(() => {
          this.$fetch()
        })
      }
    },
    tableLayout () {
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    selected: {
      handler (n) {
        // Handle changes in individual flavour checkboxes
        if (n.length === 0) {
          this.indeterminate = false
          this.allSelected = false
        } else if (n.length === this.items?.data.length) {
          this.indeterminate = false
          this.allSelected = true
        } else {
          this.indeterminate = true
          this.allSelected = false
        }
      },
      deep: true
    }
  },
  mounted () {
    this.dtKey = (Math.random() + 1).toString(36).substring(8)
    this.parsePageQuery()
    if (!this.isMobile) {
      this.searchState = true
    }
    this.$nextTick(async () => {
      await this.releaseCalls()
      this.calcHeight()
      this.isMounted = true
    })
  },
  beforeDestroy () {
    // Flush related repos
    this.flush({ repo: this.repo, method: 'index' })
    if (this.filterFields.length) {
      this.flush({ repo: this.repo, method: 'filterData' })
    }
    if (this.canSearch) {
      this.flush({ repo: this.repo, method: 'search' })
    }
  },
  methods: {
    ...page.methods,
    ...mapActions({
      call: 'repos/call',
      addCall: 'repos/addCall',
      releaseCalls: 'repos/releaseCalls',
      flush: 'repos/flush',
      flushError: 'repos/flushError'
    }),
    onFilterSubmit (filter) {
      this.tFilter = filter
      this.selected = []
      this.$emit('on-filter', filter)
      this.$nextTick(() => {
        this.$fetch()
        this.$refs?.dt?.$fetch() // try update
        this.$nextTick(() => {
          this.releaseCalls()
        })
        this.calcHeight()
      })
    },
    onTableCallAdded () {
      this.$nextTick(() => {
        this.releaseCalls()
      })
    },
    toggleFilter () {
      this.filterState = !this.filterState
      if (this.isMobile && this.filterState) {
        if (this.searchState) {
          this.hideSearch()
        }
        if (this.sortState) {
          this.hideSort()
        }
      }
    },
    hideFilter () {
      this.filterState = false
    },
    toggleSort () {
      this.sortState = !this.sortState
      if (this.isMobile && this.sortState) {
        if (this.filterState) {
          this.hideFilter()
        }
        if (this.searchState) {
          this.hideSearch()
        }
      }
    },
    hideSort () {
      this.sortState = false
    },
    toggleSearch () {
      this.searchState = !this.searchState
      if (this.isMobile && this.searchState) {
        if (this.filterState) {
          this.hideFilter()
        }
        if (this.sortState) {
          this.hideSort()
        }
      }
    },
    hideSearch () {
      this.searchState = false
    },
    onFilterShown () {
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    onFilterHidden () {
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    onSortShown () {
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    onSortHidden () {
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    onSort (order) {
      if (this.tOrder.by !== order.by || this.tOrder.dir !== order.dir) {
        this.tOrder = order
        this.$emit('on-sort', order)
      }
    },
    onCancel () {
      this.editId = null
      this.editState = false
      this.$emit('on-form-close')
    },
    onCreate () {
      this.editState = true
      this.flushError()
    },
    onEdit (id) {
      this.editId = id
      this.editState = true
      this.flushError()
    },
    onSearch (query) {
      this.searchQuery = query
      this.currentPage = 1
      this.flush({ repo: this.repo, method: 'search' })
      this.$nextTick(() => {
        this.$fetch()
        this.$refs?.dt?.$fetch()
        this.$nextTick(() => {
          this.releaseCalls()
        })
      })
    },
    async onSearchQuery (phrase) {
      try {
        this.searchBusy = true
        const query = {}
        if (this.tFilter && this.tFilter.length) {
          for (const filter of this.tFilter) {
            query[`filter[${filter.column}]`] = filter.value
          }
        }
        await this.call({
          repo: this.repo,
          method: 'search',
          phrase: btoa(unescape(decodeURI(phrase))),
          query
        })
      } finally {
        this.searchBusy = false
      }
    },
    onSubmitNew (id) {
      if (id) {
        this.onEdit(id)
      }
      this.$emit('on-submit-new', id)
    },
    onSubmit () {
      this.$fetch()
      this.$refs?.dt?.$fetch()
      this.$nextTick(() => {
        this.releaseCalls()
      })
      this.$emit('on-form-close')
    },
    onDelete (id) {
      this.$confirmDanger({
        title: this.$t('eDoc_list_deleteConfirmTitle'),
        body: this.$t('eDoc_list_deleteConfirmBody', { id }),
        okTitle: this.$t('eDoc_list_deleteConfirmOk'),
        cancelTitle: this.$t('eDoc_list_deleteConfirmCancel')
      })
        .then(async (value) => {
          if (value) {
            await this.call({
              repo: this.repo,
              method: 'delete',
              id
            })
            this.$fetch()
            this.$refs?.dt?.$fetch()
            this.$nextTick(() => {
              this.releaseCalls()
            })
          }
        })
    },
    onRestore (id) {
      this.$confirmWarning({
        title: this.$t('eDoc_list_restoreConfirmTitle'),
        body: this.$t('eDoc_list_restoreConfirmBody', { id }),
        okTitle: this.$t('eDoc_list_restoreConfirmOk'),
        cancelTitle: this.$t('eDoc_list_restoreConfirmCancel')
      })
        .then(async (value) => {
          if (value) {
            const payload = Object.assign({}, this.$refs.dt.getById(id))
            if (payload) {
              payload.deleted_at = null
              await this.call({
                repo: this.repo,
                method: 'update',
                id,
                payload
              })
              this.$fetch()
              this.$refs?.dt?.$fetch()
              this.$nextTick(() => {
                this.releaseCalls()
              })
            }
          }
        })
    },
    onCreated (id) {
    },
    metaData () {
      return this.$refs.dt && this.$refs.dt.getMeta()
    },
    calcHeight () {
      if (this.$refs.wrapper && window) {
        const $navbar = document.querySelector('.navbar')
        const windowHeight = window.innerHeight
        const navbarHeight = ($navbar && $navbar.clientHeight) || 0
        const topBarHeight = this.$refs?.topBar?.offsetHeight || 0
        const fastAddHeight = this.$refs?.fastAdd?.offsetHeight || 0
        const filterHeight = this.$refs?.filter?.$el?.offsetHeight || 0
        const sortHeight = this.$refs?.sort?.$el?.offsetHeight || 0
        const pagerHeight = this.$refs?.pager?.$el?.offsetHeight || 0
        this.height = windowHeight - navbarHeight - topBarHeight - fastAddHeight - filterHeight - sortHeight - pagerHeight
      }
    },
    onFlushSearchResults () {
      this.flush({ repo: this.repo, method: 'search' })
    },
    onResize () {
      this.$nextTick((data) => {
        if (this.deviceInfo?.type === 'mobile' || this.deviceInfo?.type === 'tablet') {
          setTimeout(() => {
            this.calcHeight()
          }, 250)
        } else {
          this.calcHeight()
        }
      })
    },
    hasSlot (name) {
      return !!this.$parent.$slots[name] ||
        !!this.$parent.$scopedSlots[name] ||
        !!this.$slots[name] ||
        !!this.$scopedSlots[name]
    },
    onPageChanged (page) {
      this.currentPage = page
      if (this.$refs.datatable) {
        this.$refs.datatable.$el.scrollTo({ top: 0 })
      }
    },
    onPageSizeChanged (size) {
      this.pageSize = parseInt(size, 10)
    },
    tBodyTrClass (item) {
      const isSelected = this.selected.includes(item.id)
      if (isSelected) {
        return 'table-info'
      }
      const variant = typeof this.setItemVariant === 'function'
        ? this.setItemVariant(item)
        : this.setItemVariant
      return variant !== '' ? `table-${variant}` : ''
    },
    cardBodyClass (item) {
      const isSelected = this.selected.includes(item.id)
      if (isSelected) {
        return 'info'
      }
      return typeof this.setItemVariant === 'function'
        ? this.setItemVariant(item)
        : this.setItemVariant
    },
    collapseCards () {
      if (this.$refs.dt) {
        this.$refs.dt.collapseCards()
      }
    },
    expandCards () {
      if (this.$refs.dt) {
        this.$refs.dt.expandCards()
      }
    },
    onSelectAll (checked) {
      if (checked) {
        this.selected = this.items?.data.map(i => parseInt(i.id, 10))
        this.allSelected = true
      } else {
        this.selected = []
        this.allSelected = false
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss">
.item-list-wrapper {
  position: relative;
}
</style>
