<template>
  <div v-if="state" class="text-center py-4 h-100 d-flex justify-content-center align-items-center flex-column">
    <h1 class="text-danger">
      <font-awesome-icon :icon="['fas', 'triangle-exclamation']" size="lg" />
    </h1>
    <h2 class="mb-4 w-100 text-muted">
      {{ message }}
    </h2>
    <div>
      <b-btn variant="outline-secondary" class="mr-2" @click="onCancel">
        <font-awesome-icon icon="rotate-left" />
        Cancel
      </b-btn>
      <b-btn variant="outline-warning" @click="onReload">
        <font-awesome-icon icon="rotate-right" />
        Reload
      </b-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'Record not found'
    }
  },
  data () {
    return {
      state: this.value
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$nuxt.$loading.finish()
    })
  },
  watch: {
    value (n) {
      this.state = n
    }
  },
  methods: {
    onCancel () {
      this.$emit('cancel')
    },
    onReload () {
      this.$emit('reload')
    }
  }
}
</script>

<style scoped>

</style>
